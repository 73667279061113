import React from 'react'

const AboutMe = () => {
  return (
    <div className="aboutMeContainer">
    <h2 className="headingTitle">AboutMe</h2>
    <div className="aboutMeContent">
      <img className= 'profilePic' src="Profile Pic copy.jpeg" alt="profile pic" />
      <p className="aboutMeText">My name is Aaron Dizon and I am a software engineer.</p>
      <p classname="technoligiesTitle"> Technologies: HTML/CSS/JS, PostgreSQL/Sequelize, Node.Js, REACT, Python, Flask, D3.js</p>
    </div>
    </div>
  )
}

export default AboutMe