import React from 'react'

const RedditTimer = () => {
  return (
    <div className="individualProjectContainer">
        <h3 className= "individualProjectTitle">Reddit Timer</h3>
        <a href='https://reddit-timer-e209ed6ac526.herokuapp.com/' target='blank'>
          <img className="projectImage" src="/RedditTimerImage.png" />
        </a>
        <div className="textContainer">
            <p className="projectDescription">This website present a visiual representation of traffic for subreddit posts. This app is useful as a guide to help the user decide when the "hot" times to post are. The site implements Javascript and React.js, along with D3.js for the visual representation of the data.</p>
            <a className="link" href='https://reddit-timer-e209ed6ac526.herokuapp.com/' target='blank'>Visit Site Here!</a>
        </div>

    </div>
  )
}

export default RedditTimer