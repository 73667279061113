import React from 'react'

const GhibliForums = () => {
  return (
    <div className="individualProjectContainer">

        <h3 className= "individualProjectTitle">Ghibli Forums</h3>
        <a href='https://ghibli-forums.herokuapp.com/' target='blank'>
         <img className="projectImage" src="/GhibliForumsImage.png" />
        </a>
        <div className="textContainer">
          <p className="projectDescription">This website is essentially a forum page for each individual Ghibli Films. The website implements the concepts of PostgreSQL, Express and Node.Js to link the back-end server side of the project with the user interface, made with React and styled with CSS.
          </p>
          <a className="link" href='https://ghibli-forums.herokuapp.com/' target='blank'>Visit Site Here!</a>
        </div>
    </div>
  )
}

export default GhibliForums