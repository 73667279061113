import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import GhibliForums from './Projects/GhibliForums';
import Lizard from './Projects/Lizard';
import Plantify from './Projects/Plantify';
import RedditTimer from './Projects/RedditTimer';

const Projects = () => {

    const [redditModalIsOpen, setRedditModalIsOpen] = useState(false)
    const [ghibliModalIsOpen, setGhibliModalIsOpen] = useState(false)
    const [lizardModalIsOpen, setLizardModalIsOpen] = useState(false)
    const [plantifyModalIsOpen, setPlantifyModalIsOpen] = useState(false)

    const redditImageClick = () => {
        setRedditModalIsOpen(true)
    }
    const ghibliImageClick = () => {
        setGhibliModalIsOpen(true)
    }
    const lizardImageClick = () => {
        setLizardModalIsOpen(true)
    }
    // const plantifyImageClick = () => {
    //     setPlantifyModalIsOpen(true)
    // }


  return (
    <div className="projectsContainer">
        <h2 className="headingTitle"> Projects </h2>
        <div className="projectImageContainer">
          
                <img className= 'projectImage' src="RedditTimerImage.png" alt="reddit timer" onClick={()=> redditImageClick()} />

                <Modal className="modalContainer" isOpen={redditModalIsOpen}>
                    <RedditTimer />
                    <p className='modalButton' onClick={()=>{setRedditModalIsOpen(false)}}> Close</p>
                </Modal>
        
                <img className="projectImage" src="/GhibliForumsImage.png" alt="ghibli forums" onClick={()=>ghibliImageClick()} />

                <Modal className= 'modalContainer' isOpen={ghibliModalIsOpen}>
                    <GhibliForums />
                    <p className='modalButton' onClick={()=>{setGhibliModalIsOpen(false)}}>Close</p>
                </Modal>       
       

                <img className="projectImage" src="/LizardImage.png" alt="lizard game" onClick={()=>lizardImageClick()}/>

                <Modal className= 'modalContainer' isOpen={lizardModalIsOpen}>
                    <Lizard />
                    <p className='modalButton' onClick={()=>{setLizardModalIsOpen(false)}}>Close</p>
                </Modal> 

 
                {/* <img className="projectImage" src="/PlantifyImage.png" alt="plantify" onClick={()=>plantifyImageClick()}/>

                <Modal className= 'modalContainer' isOpen={plantifyModalIsOpen}>
                    <Plantify />
                    <p className='modalButton' onClick={()=>{setPlantifyModalIsOpen(false)}}>Close</p>
                </Modal>  */}
                
         



        </div>
    </div>
  )
}

export default Projects