import './App.css';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Contact from './components/Contact';

function App() {
  return (
    <div className="app">
    
      <h className='portfolioTitle'>Hello and Welcome!</h>
      <AboutMe />

      <Projects />

      <Contact />
    </div>
  );
}

export default App;
