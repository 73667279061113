import React from 'react'

const Lizard = () => {
  return (
    <div className="individualProjectContainer">
        <h3 className= "individualProjectTitle">Lizard</h3>
        <a href='https://lizard-game.herokuapp.com/' target='blank'>
          <img className="projectImage" src="/LizardImage.png"/>
        </a>
        <div className="textContainer">
        <p className="projectDescription">Introducing the “long lost cousin” of the Snake Game! Control the Lizard to eat food and watch out for the obstacles you produce after gaining points. This site incorporates HTML Canvas with React. The game also implements user authentication, score keeping, and a gacha system where you can pull different colors of the lizard with tokens you earn from playing games.</p>
        <a className="link" href='https://lizard-game.herokuapp.com/' target='blank'>Visit Site Here!</a>
        </div>
    </div>
  )
}

export default Lizard